import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/pages/LoginPage.vue'
import Login2 from '../views/pages/LoginPage2.vue'
import DashBoard from '../views/pages/DashBoard.vue'
import RegisterPage from '../views/pages/RegisterPage.vue'
import PostPage from '../views/pages/Feeds/PostPage.vue'
import PostDetails from '../views/pages/Feeds/PostDetails.vue'
import MembersManagement from '../views/pages/MembersManagement/MembersManagement.vue'

const routes = [
  {
    path: '/',
    name: 'login-page',
    component: Login,
    // meta: { title: 'login' },
  },
  {
    path: '/about',
    name: 'about',
  
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login-page2',
    name: 'login-page2',
    component: Login2,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
  },
  {
    path: '/post-page',
    name: 'post-page',
    component: PostPage,
  },
  {
    path: '/post-details',
    name: 'post-details',
    component: PostDetails,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashBoard,
    // meta: { requiresAuth: true }, 
  },
  {
    path: '/members-management',
    name: 'members-management',
    component: MembersManagement,
    // meta: { requiresAuth: true }, 
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!localStorage.getItem('token')) {
      // Redirect to login if not authenticated
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

 


// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router
