<!-- <template>
<div>
    this is post body
    <div class="center">
    <p>This div is horizontally and vertically centered.</p>
</div>
</div>
</template>
<style scoped>
.center {
  border: 5px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
</style> -->




<template>
    <div class="center">
        <div>
            <div class="form-container">
                <p class="title">MVP </p>
                <form @submit.prevent="login">
                    <div class="form">
                        <input type="text" v-model="user_name" required class="input" placeholder="User Name">
                        <input type="password" v-model="password" required class="input" placeholder="Password">
                        <p class="page-link">
                            <span class="page-link-label">Forgot Password?</span>
                        </p>
                        <button class="form-btn" type="submit" :disabled="loading">Login</button>
                        <p v-if="error" class="error">{{ error }}</p>
                    </div>
                    <p class="sign-up-label">
                        Don't have an account?<span class="sign-up-link" @click="this.$router.push('register')">Sign
                            up</span>
                    </p>
                    <div class="buttons-container">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {
            user_name: '',
            password: '',
        };
    },
    methods: {
        login() {
            const credentials = {
                user_name: this.user_name,
                password: this.password,
            };
            // axios.post('http://127.0.0.1:8000/api/login', credentials)
            axios.post('/api/login', credentials)
                .then(response => {

                    localStorage.setItem('access_token', response.data.access_token);

                    this.$router.push('/dashboard');
                })
                .catch(error => {
                    console.error('Login failed', error);
                    alert('User name and password is not correct!');
                });


            // axios.post('/api/login', credentials, {
            //     withCredentials: true,
            //     headers: {
            //         'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            //         // ... other headers
            //     },
            // })

            // axios.post('https://api-superorg.stagingurls.com/api/login', credentials, {

            //     withCredentials: true,
            //     headers: {
            //         "Access-Control-Allow-Origin": "*",
            //         "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            //         "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            //     }
            // })
            // .then(response => {
            //     localStorage.setItem('access_token', response.data.access_token);

            //     this.$router.push('/dashboard');
            // })
            // .catch(error => {
            //     console.error('Login failed', error);
            // });
        },
    },
};
</script>

<style scoped>
.form-container {
    width: 350px;
    height: 500px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
}

.title {
    text-align: center;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin: 10px 0 30px 0;
    font-size: 28px;
    font-weight: 800;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 15px;
}

.input {
    border-radius: 20px;
    border: 1px solid #c0c0c0;
    outline: 0 !important;
    box-sizing: border-box;
    padding: 12px 15px;
}

.page-link {
    text-decoration: underline;
    margin: 0;
    text-align: end;
    color: #747474;
    text-decoration-color: #747474;
}

.page-link-label {
    cursor: pointer;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-size: 9px;
    font-weight: 700;
}

.page-link-label:hover {
    color: #000;
}

.form-btn {

    padding: 10px 15px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    border-radius: 20px;
    border: 0 !important;
    outline: 0 !important;
    background: teal;
    color: white;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form-btn:active {
    box-shadow: none;
}

.sign-up-label {
    margin: 0;
    font-size: 10px;
    color: #747474;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.sign-up-link {
    margin-left: 1px;
    font-size: 11px;
    text-decoration: underline;
    text-decoration-color: teal;
    color: teal;
    cursor: pointer;
    font-weight: 800;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    gap: 15px;
}

.apple-login-button,
.google-login-button {
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-size: 11px;
    gap: 5px;
}

.apple-login-button {
    background-color: #000;
    color: #fff;
    border: 2px solid #000;
}

.google-login-button {
    border: 2px solid #747474;
}

.apple-icon,
.google-icon {
    font-size: 18px;
    margin-bottom: 1px;
}

.error {
    color: red;
    margin-top: 10px;
}

.center {
    /* border: 5px solid; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}
</style>


