
<template>
    <div>



        <div v-for="post in posts" :key="post.id" class="post">
            <!-- <div v-for="post in filteredPosts" :key="post.id" class="post"> -->
            <!-- <h3>Title {{ post.title }}</h3> -->
            <h3>creator {{ post.user.name }}</h3>
            <h3>stats {{ post.user.stats }}</h3>
            <p> Content {{ post.content }}</p>


            <div v-if="post.comments.length > 0">
                <h4>Comments:</h4>
                <ul>
                    <li v-for="comment in post.comments" :key="comment.id">
                        <strong>{{ comment.user?.name || 'Anonymous' }}:</strong> {{ comment.content }}
                    </li>
                </ul>
            </div>
            <!-- Add a comment form -->
            <!-- <div>
                <textarea v-model="newComment" placeholder="Add a comment"></textarea>
                <button @click="addComment(post.id)">Add Comment</button>
            </div> -->
            <p class="input-container">
                <input type="text" v-model="newComment" placeholder="Enter comment" name="text" id="text"
                    class="input-field" autocomplete="name">

                <button @click="addComment(post.id)">Add Comment</button>
            </p>
            <button @click="updateStatus(post.id)">Update Status to Pinned</button>
            <div>
                <button @click="likePost(post.id)">Like</button>
                <span>{{ post.total_likes }} likes</span>
            </div>

            <div>
                <button @click="heartReact(post.id)">Heart React</button>
                <span>{{ post.total_hearts }} heart reacts</span>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {
            posts: [],
            newComment: '',
            // searchQuery: '',
        };
    },
    computed: {
        // filteredPosts() {
        //     // Filter posts based on the search query
        //     return this.posts.filter(post =>
        //         post.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        //     );
        // },
    },
    mounted() {
        // Fetch posts when the component is mounted
        this.fetchPosts();
    },

    methods: {
        fetchPosts() {
            // Make a GET request to the backend API endpoint
            axios.get('http://127.0.0.1:8000/api/get-post', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    this.posts = response.data;
                })
                .catch(error => {
                    console.error('Error fetching posts:', error);
                });
        },
        addComment(postId) {
            // Make a POST request to the backend API to add a comment to the post
            axios.post(`https://api-superorg.stagingurls.com/api/posts/${postId}/comments`, { content: this.newComment }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Comment added successfully:', response.data);
                    // Clear the new comment input or refresh the posts
                    this.newComment = '';
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error adding comment:', error);
                });
        },
        likePost(postId) {
            // Make a POST request to the backend API to like the post
            axios.post(`https://api-superorg.stagingurls.com/api/posts/${postId}/likes`, null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Post liked successfully:', response.data);
                    // Update the like count or refresh the posts
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error liking post:', error);
                });
        },
        heartReact(postId) {
            // Make a POST request to the backend API to heart react to the post
            axios.post(`https://api-superorg.stagingurls.com/api/posts/${postId}/hearts`, null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Post heart reacted successfully:', response.data);
                    // Update the heart react count or refresh the posts
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error heart reacting to post:', error);
                });
        },
        updateStatus(postId) {
            // Make a PUT request to update the status to "pinned"
            axios.put(`http://127.0.0.1:8000/api/posts/${postId}/update-status`, { status: 'pinned' }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Status updated successfully:', response.data);
                    // Refresh the posts after updating status
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error updating status:', error);
                });
        },
        // performSearch() {
        //     // You can add debouncing here to avoid making too many requests in a short time
        //     this.fetchPosts();
        // },
    },
};
</script>

<style scoped>
.post {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
}
</style>
  