<template>
    <div>


        <input v-model="search" type="text" id="search" placeholder="Search Name" class="search-name">
        <ul class="grid">

            <li class="grid-item" v-for="member in filteredMembers" :key="member.id">

                <article>

                    <img class="div-picture"
                        src="https://images.pexels.com/photos/2754200/pexels-photo-2754200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="alternatetext">
                    <span class="full-name"> Name: {{ member.name }}</span><br>
                    <span class="short-bio"> Short Bio: {{ member.short_bio }}</span>
                    <span class="gender"> Gender: {{ member.gender }}</span>
                    <br>
                    <!-- <button @click="deleteMember(member.id)">Delete</button> -->
                    <button @click="toggleEditDropdown(member.id)" class="update-button">Update</button>
                </article>

                <div v-if="editedMemberId === member.id" class="dropdown-modal">
                    <!-- <button class="close-button" @click="closeModal">Close</button> -->
                    <hr>
                    <h3>Edit Member</h3>
                    
                    <form @submit.prevent="updateMember(member.id)">
                        <label for="name">Status:</label>
                        <select class="status-drop-down" v-model="editedMember.status" required>
                            <option value="1">Activated</option>
                            <option value="0">De Activated</option>
                        </select>
                        <button class="update-button-modal" type="submit">Update Member</button>
                        <!-- <button @click="closeModal">Close</button> -->
                    </form>
                </div>
            </li>
        </ul>
    </div>
</template>




<script>
import axios from 'axios';
export default {
    data() {
        return {
            members: [],
            editedMemberId: null,
            editedMember: {
                id: null,
                name: '',
            },
            search: '',
        };
    },
    computed: {
        filteredMembers() {
            return this.members.filter(member =>
                member.name.toLowerCase().includes(this.search.toLowerCase())
            );
        },
    },
    created() {
        this.fetchMembers();
    },
    methods: {
        async fetchMembers() {
            try {
                const response = await axios.get('/api/membersmanagement');
                this.members = response.data;
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        },
        // async deleteMember(id) {
        //     try {
        //         await axios.delete(`/api/membersmanagement/${id}`);
        //         this.fetchMembers();
        //     } catch (error) {
        //         console.error('Error deleting member:', error);
        //     }
        // },
        toggleEditDropdown(memberId) {
            if (this.editedMemberId === memberId) {
                this.editedMemberId = null;
            } else {
                this.editedMemberId = memberId;
                this.editedMember = { ...this.members.find(member => member.id === memberId) };
            }
        },
        async updateMember(memberId) {
            try {
                await axios.put(`/api/update_membersmanagement/${memberId}`, this.editedMember);
                this.editedMemberId = null;
                this.fetchMembers();
            } catch (error) {
                console.error('Error updating member:', error);
            }
        },
    },
};
</script>




<style scoped>
.grid {
    margin-left: 20px;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
    gap: 1rem;
}

/*
    👇 just to make this demo beautiful for you
*/

* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    padding: 2rem;
    background-size: cover;
    font-family: 'Flow Circular', cursive;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='https://images.pexels.com/photos/2754200/pexels-photo-2754200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' viewBox='0 0 700 700' width='700' height='700'%3E%3Cdefs%3E%3ClinearGradient gradientTransform='rotate(230, 0.5, 0.5)' x1='50%25' y1='0%25' x2='50%25' y2='100%25' id='ffflux-gradient'%3E%3Cstop stop-color='%23FD6C13' stop-opacity='1' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23E63E00' stop-opacity='1' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3Cfilter id='ffflux-filter' x='-20%25' y='-20%25' width='140%25' height='140%25' filterUnits='objectBoundingBox' primitiveUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.001 0.001' numOctaves='2' seed='221' stitchTiles='stitch' x='0%25' y='0%25' width='100%25' height='100%25' result='turbulence'%3E%3C/feTurbulence%3E%3CfeGaussianBlur stdDeviation='0 0' x='0%25' y='0%25' width='100%25' height='100%25' in='turbulence' edgeMode='duplicate' result='blur'%3E%3C/feGaussianBlur%3E%3CfeBlend mode='color-dodge' x='0%25' y='0%25' width='100%25' height='100%25' in='SourceGraphic' in2='blur' result='blend'%3E%3C/feBlend%3E%3C/filter%3E%3C/defs%3E%3Crect width='700' height='700' fill='url(%23ffflux-gradient)' filter='url(%23ffflux-filter)'%3E%3C/rect%3E%3C/svg%3E");

    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' viewBox='0 0 700 700' width='700' height='700'%3E%3Cdefs%3E%3ClinearGradient gradientTransform='rotate(230, 0.5, 0.5)' x1='50%25' y1='0%25' x2='50%25' y2='100%25' id='ffflux-gradient'%3E%3Cstop stop-color='%23FD6C13' stop-opacity='1' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23E63E00' stop-opacity='1' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3Cfilter id='ffflux-filter' x='-20%25' y='-20%25' width='140%25' height='140%25' filterUnits='objectBoundingBox' primitiveUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.001 0.001' numOctaves='2' seed='221' stitchTiles='stitch' x='0%25' y='0%25' width='100%25' height='100%25' result='turbulence'%3E%3C/feTurbulence%3E%3CfeGaussianBlur stdDeviation='0 0' x='0%25' y='0%25' width='100%25' height='100%25' in='turbulence' edgeMode='duplicate' result='blur'%3E%3C/feGaussianBlur%3E%3CfeBlend mode='color-dodge' x='0%25' y='0%25' width='100%25' height='100%25' in='SourceGraphic' in2='blur' result='blend'%3E%3C/feBlend%3E%3C/filter%3E%3C/defs%3E%3Crect width='700' height='700' fill='url(%23ffflux-gradient)' filter='url(%23ffflux-filter)'%3E%3C/rect%3E%3C/svg%3E"); */
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

article {
    --gray: #eee;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    color: var(--gray);
    box-shadow:
        0.3px 0.5px 0.7px hsl(23deg 93% 31% / 0.36),
        0.8px 1.6px 2px -0.8px hsl(23deg 93% 31% / 0.36),
        2.1px 4.1px 5.2px -1.7px hsl(23deg 93% 31% / 0.36),
        5px 10px 12.6px -2.5px hsl(23deg 93% 31% / 0.36);
}

div {
    aspect-ratio: 4/3;
    background-color: var(--gray);
}

h2,
p {
    padding: 0 10px;
}

.full-name {
    font-size: 25px;
    color: black;
    font-weight: 600;
}

.short-bio {
    font-size: 20px;
    color: rgb(42, 39, 39);
    font-weight: 600;
}

.gender {
    font-size: 20px;
    color: rgb(42, 39, 39);
    font-weight: 600;
}

.update-button {
    height: 55px;
    width: 110px;
    margin-left: 63%;
    border-radius: 10px;
    font-size: 25px;
    border-style: solid;
    border-color: brown;
    border-width: 4px;
}

.dropdown-modal {
    position: absolute;
    top: 50px;
    /* Adjust the top position as needed */
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    z-index: 1000;
    margin-left: 45%;
    width: 430px;
    height: 200px;
    border-radius: 5%;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    
}

.search-name {
    border-style: solid;
    border-width: 3px;
    height: 60px;
    width: 350px;
    margin: 25px 50px;
    border-radius: 10px;
    font-size: 25px;
    padding: 20px 20px;
}

.div-picture {
    height: 300px;
    width: 450px;
}
.close-button{
    margin-left: 80%;
    height: 45px;
    width: 65px;
    border-style: solid;
    border-color: brown;
    border-width: 3px;
    border-radius: 10%;
    font-size: 20px;
}
.status-drop-down{
    height: 45px;
    width: 160px;
    border-style: solid;
    border-color: brown;
    border-width: 3px;
    border-radius: 10%;
    margin-left: 10%;
    font-size: 20px;
    font-weight: 600;
}
.update-button-modal{
    margin-left: 5%;
    height: 45px;
    width: 130px;
    border-radius: 10%;
    font-size: 15px;
    font-weight: 600;
    background-color: brown;
}
</style>