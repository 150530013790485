
<template>
    <div class="body">
        <!-- 
        <h2>Posts</h2>
        <div v-for="post in posts" :key="post.id" class="post">
            <h3>creator {{ post.user.name }}</h3>
            <h3>stats {{ post.user.stats }}</h3>
            <p> Content {{ post.content }}</p>


            <div v-if="post.comments.length > 0">
                <h4>Comments:</h4>
                <ul>
                    <li v-for="comment in post.comments" :key="comment.id">
                        <strong>{{ comment.user?.name || 'Anonymous' }}:</strong> {{ comment.content }}
                    </li>
                </ul>
            </div>

            <p class="input-container">
                <input type="text" v-model="newComment" placeholder="Enter comment" name="text" id="text"
                    class="input-field" autocomplete="name">
                <label class="input-label" for="text">Add Comment</label>
                <button @click="addComment(post.id)">Add Comment</button>
            </p>
            <button @click="updateStatus(post.id)">Update Status to Pinned</button>
            <div>
                <button @click="likePost(post.id)">Like</button>
                <span>{{ post.total_likes }} likes</span>
            </div>

            <div>
                <button @click="heartReact(post.id)">Heart React</button>
                <span>{{ post.total_hearts }} heart reacts</span>
            </div>
        </div> -->


        <section>


            <!-- Display the first already pinned post -->
            <!-- <div v-if="firstPinnedPost">
                <h3>First Pinned Post:</h3>
                <p>{{ firstPinnedPost.description }}</p> -->
            <!-- Add other details as needed -->
            <!-- </div> -->

            <!-- Display the total count of already pinned posts -->
            <!-- <div v-if="totalPinnedPosts !== null">
                <p>Total Pinned Posts: {{ totalPinnedPosts }}</p>
            </div> -->

            <div v-if="posts.length === 0">
               <span style="font-size: 45px; font-weight: bolder;">No post!</span>
            </div>




            <!-- <div class="card" style="max-width: 42rem" v-for="post in posts" :key="post.id"> -->
            <!-- <input type="date" v-model="filterDate" @change="fetchPosts" />

            <div v-for="post in filteredPosts" :key="post.id" class="post"> -->
            <!-- Add a date input for filtering -->
            <!-- <input type="date" v-model="filterDate" @change="fetchPosts" />

            <div v-for="post in posts" :key="post.id" class="post"> -->

            <!-- Add a date input for filtering -->
            <h3>Filter using date</h3>
            <input type="date" v-model="specificDate" @change="fetchPosts" />

            <div v-for="post in posts" :key="post.id" class="post" style="max-width: 42rem">
                <div class="card-body">
                    <!-- Data -->
                    <div class="d-flex mb-3">
                        <a href="">
                            <img src="https://mdbcdn.b-cdn.net/img/new/avatars/18.webp" class="border rounded-circle me-2"
                                alt="Avatar" style="height: 40px" />
                        </a>
                        <div>
                            <a href="" class="text-dark mb-0">
                                <strong> {{ post.user.name }}</strong>
                            </a>
                            <a href="" class="text-muted d-block" style="margin-top: -6px">
                                <small>10h</small>
                            </a>
                        </div>
                    </div>
                    <!-- Description -->
                    <div>
                        <h3>stats {{ post.stats }}</h3>
                        <p>Description: {{ post.description }}</p>
                        <p>Date posted: {{ post.created_at }}</p>
                        <p>Time posted: {{ post.time_posted }}</p>
                    </div>
                </div>
                <!-- Media -->
                <div class="bg-image hover-overlay ripple rounded-0" data-mdb-ripple-color="light">
                    <img src="https://mdbcdn.b-cdn.net/img/new/standard/people/077.webp" class="w-100" />
                    <a href="#!">
                        <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                    </a>
                </div>
                <!-- Media -->
                <!-- Interactions -->
                <div class="card-body">
                    <!-- Reactions -->
                    <div class="d-flex justify-content-between mb-3">
                        <div>
                            <a href="">
                                <i class="fas fa-thumbs-up text-primary"></i>
                                <i class="fas fa-heart text-danger"></i>
                                <!-- <span>124</span> -->
                            </a>
                        </div>
                        <div>
                            <a href="" class="text-muted"> Total Comments: {{ post.total_comments }} </a>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between text-center border-top border-bottom mb-4">

                        <!-- <button @click="likePost(post.id)" type="button" class="btn btn-link btn-lg"
                            data-mdb-ripple-color="dark">
                            <i class="fas fa-thumbs-up me-2"></i>
                            <span style="font-weight: 900;">{{ post.total_likes }} likes</span>
                        </button> -->
                        <button @click="likePost(post.id)" type="button" class="btn btn-primary"
                            data-mdb-ripple-color="dark">
                            <i class="bi bi-hand-thumbs-up-fill"></i>
                            <span style="font-weight: 900;  margin-left: .5%;">{{ post.total_likes }} likes</span>
                        </button>


                        <button @click="heartReact(post.id)" type="button" class="btn btn-danger"
                            data-mdb-ripple-color="dark">
                            <i class="bi bi-heart-fill"></i>
                            <span style="font-weight: 900; margin-left: .5%;">{{ post.total_hearts }} Hearts</span>
                        </button>

                        <!-- <div class="button-borders">
                            <button @click="updateStatus(post.id)" class="primary-button">
                                <span class="bi bi-pin"></span>
                                {{ post.status === 'pinned' ? 'Already pin' : 'Pin' }} Post
                            </button>
                        </div> -->
                        <!-- Display pinned status -->


                        <!-- Display comments... -->

                        <!-- Add a button to toggle pinned status -->

                        <div class="button-borders">
                            <button @click="togglePinnedStatus(post.id)" :disabled="post.status" class="primary-button">
                                <span class="bi bi-pin"></span>
                                {{ post.status ? 'Already Pinned' : 'Pin Post' }} Post
                            </button>
                        </div>


                        <!-- <button @click="togglePinnedStatus(post.id)">
                            {{ post.pinned ? 'Unpin' : 'Pin' }} Post
                        </button> -->

                    </div>


                    <div class="d-flex lg-100">
                        <a href="">
                            <img src="https://mdbcdn.b-cdn.net/img/new/avatars/18.webp" class="border rounded-circle me-2"
                                alt="Avatar" style="height: 40px" />
                        </a>
                        <div class="form-outline w-100">
                            <textarea v-model="newComment" placeholder="Write a comment" class="form-control"
                                style="border-style: solid; border-width: 3px; border-color: black;" id="textAreaExample"
                                rows="3" cols="70"></textarea>
                            <!-- <label class="form-label" for="textAreaExample">Write a comment</label> -->
                            <!-- <label class="input-label" for="text">Add Comment</label> -->
                            <button @click="addComment(post.id)" class="btn-comment">Add Comment</button>
                        </div>

                    </div>
                    <div v-if="post.comments.length > 0">
                        <h4>Comments:</h4>
                        <ul>
                            <li v-for="comment in post.comments" :key="comment.id">
                                <strong>{{ comment.user.name || 'Anonymous' }}:</strong> {{ comment.content }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>




















        </section>
    </div>
</template>
  
<script>
import axios from 'axios';
export default {
    data() {
        return {
            posts: [],
            newComment: '',
            specificDate: null, // Specific date for filtering
        };
    },

    mounted() {
        this.fetchPosts();
    },

    methods: {
        // fetchPosts() {
        //     // Make a GET request to the backend API endpoint with Authorization header
        //     axios.get('http://127.0.0.1:8000/api/get-post', {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        //         },
        //     })
        //         .then(response => {
        //             this.posts = response.data;
        //         })
        //         .catch(error => {
        //             console.error('Error fetching posts:', error);
        //         });
        // },

        fetchPosts() {

            //     ? `http://127.0.0.1:8000/api/get-post?specific_date=${this.specificDate}`
            //     : 'http://127.0.0.1:8000/api/get-post';
            const url = this.specificDate
                ? `/api/get-post?specific_date=${this.specificDate}`
                : '/api/get-post';

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    this.posts = response.data;
                })
                .catch(error => {
                    console.error('Error fetching posts:', error);
                });

        },
        // fetchPosts() {

        //     // axios.get('http://127.0.0.1:8000/api/get-post', {
        //     // axios.get('api/get-post', {
        //     axios.get('http://127.0.0.1:8000/api/get-post', {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        //         },
        //     })
        //         .then(response => {
        //             this.posts = response.data;
        //         })
        //         .catch(error => {
        //             console.error('Error fetching posts:', error);
        //         });
        // },

        fetchFirstPinnedPost() {
            axios.get('api/posts/first-pinned', {
            // axios.get('http://127.0.0.1:8000/api/posts/first-pinned', {

                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    this.firstPinnedPost = response.data;
                })
                .catch(error => {
                    console.error('Error fetching first pinned post:', error);
                });
        },

        fetchTotalPinnedPosts() {
            axios.get('api/posts/total-pinned', {
            // axios.get('http://127.0.0.1:8000/api/posts/total-pinned', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    this.totalPinnedPosts = response.data.total;
                })
                .catch(error => {
                    console.error('Error fetching total pinned posts:', error);
                });
        },

        addComment(postId) {
            if (!this.newComment.trim()) {
                // Display an error message or handle it as needed
                console.error('Comment cannot be empty');
                return;
            }
            // axios.post(`http://127.0.0.1:8000/api/posts/${postId}/comments`, { content: this.newComment }, {
            axios.post(`api/posts/${postId}/comments`, { content: this.newComment }, {
            // axios.post(`http://127.0.0.1:8000/api/posts/${postId}/comments`, { content: this.newComment }, {

                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Comment added successfully:', response.data);

                    this.newComment = '';
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error adding comment:', error);
                });
        },
        likePost(postId) {

            // axios.post(`http://127.0.0.1:8000/api/posts/${postId}/likes`, null, {
                axios.post(`api/posts/${postId}/likes`, null, {

                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Post liked successfully:', response.data);
                    // Update the like count or refresh the posts
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error liking post:', error);
                    alert('You have already liked this post!');
                });
        },
        heartReact(postId) {
            // Make a POST request to the backend API to heart react to the post
            // axios.post(`http://127.0.0.1:8000/api/posts/${postId}/hearts`, null, {
                axios.post(`api/posts/${postId}/hearts`, null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Post heart reacted successfully:', response.data);
                    // Update the heart react count or refresh the posts
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error heart reacting to post:', error);
                    alert('You have already heart this post!');
                });
        },
        // updateStatus(postId) {
        //     // Make a PUT request to update the status to "pinned"
        //     // axios.put(`http://127.0.0.1:8000/api/posts/${postId}/update-status`, { status: 'pinned' }, {
        //     axios.put(`api/posts/${postId}/update-status`, { status: 'pinned' }, {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        //         },
        //     })
        //         .then(response => {
        //             console.log('Status updated successfully:', response.data);
        //             // Refresh the posts after updating status
        //             this.fetchPosts();
        //         })
        //         .catch(error => {
        //             console.error('Error updating status:', error);

        //         });
        // },

        togglePinnedStatus(postId) {
            // Make a POST request to the backend API to toggle the status with Authorization header
            // axios.post(`http://127.0.0.1:8000/api/posts/${postId}/toggle-status`, null, {
                axios.post(`/api/posts/${postId}/toggle-status`, null, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Status toggled successfully:', response.data);
                    // Refresh the posts after toggling the status
                    this.fetchPosts();
                })
                .catch(error => {
                    console.error('Error toggling status:', error);
                });
        },
        formatDate(date) {
            // Format the date to match the input type="date" format (YYYY-MM-DD)
            const formattedDate = new Date(date).toISOString().split('T')[0];
            return formattedDate;
        },
    },
};
</script>

<style scoped>
.primary-button {
    font-family: 'Ropa Sans', sans-serif;
    /* font-family: 'Valorant', sans-serif; */
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.05rem;
    border: 1px solid #0E1822;
    padding: 0.8rem 2.1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 531.28 200'%3E%3Cdefs%3E%3Cstyle%3E .shape %7B fill: %23FF4655 /* fill: %230E1822; */ %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolygon class='shape' points='415.81 200 0 200 115.47 0 531.28 0 415.81 200' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #0E1822;
    background-size: 200%;
    background-position: 200%;
    background-repeat: no-repeat;
    transition: 0.3s ease-in-out;
    transition-property: background-position, border, color;
    position: relative;
    z-index: 1;
}

.primary-button:hover {
    border: 1px solid #FF4655;
    color: white;
    background-position: 40%;
}

.primary-button:before {
    content: "";
    position: absolute;
    background-color: #0E1822;
    width: 0.2rem;
    height: 0.2rem;
    top: -1px;
    left: -1px;
    transition: background-color 0.15s ease-in-out;
}

.primary-button:hover:before {
    background-color: white;
}

.primary-button:hover:after {
    background-color: white;
}

.primary-button:after {
    content: "";
    position: absolute;
    background-color: #FF4655;
    width: 0.3rem;
    height: 0.3rem;
    bottom: -1px;
    right: -1px;
    transition: background-color 0.15s ease-in-out;
}

.button-borders {
    margin-top: 2%;
    position: relative;
    width: fit-content;
    height: fit-content;
}

.button-borders:before {
    content: "";
    position: absolute;
    width: calc(100% + 0.5em);
    height: 50%;
    left: -0.3em;
    top: -0.3em;
    border: 1px solid #0E1822;
    border-bottom: 0px;
    /* opacity: 0.3; */
}

.button-borders:after {
    content: "";
    position: absolute;
    width: calc(100% + 0.5em);
    height: 50%;
    left: -0.3em;
    bottom: -0.3em;
    border: 1px solid #0E1822;
    border-top: 0px;
    /* opacity: 0.3; */
    z-index: 0;
}

.shape {
    fill: #0E1822;
}






.btn-comment {
    margin-top: 2%;
    margin-left: 65%;
    background: #fbca1f;
    font-family: inherit;
    padding: 0.6em 1.3em;
    font-weight: 900;
    font-size: 18px;
    border: 3px solid black;
    border-radius: 0.4em;
    box-shadow: 0.1em 0.1em;
    cursor: pointer;
}

.btn-comment:hover {
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em;
}

.btn-comment:active {
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}

/* 
.btn-comment {
    margin-top: 10px;
    margin-left: 75%;
    height: 50px;
    border-radius: 10%;
    border-style: solid;
    border-width: 2px;
    color: blue;
    background-color: blanchedalmond;
} */
</style>
  