<template>
    <div>
        <Login />
    </div>
</template>
<script>
import Login from './LoginPage.vue';
export default {
    name: 'HomeView',
    components: {
        Login
    },


}

</script>