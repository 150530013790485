<!-- <template>
<div>
    this is post body
    <div class="center">
    <p>This div is horizontally and vertically centered.</p>
</div>
</div>
</template>
<style scoped>
.center {
  border: 5px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
</style> -->

<template>
    <div>
        <h2>Create Post</h2>
        <form @submit.prevent="createPost">
            <!-- <label>Title:</label>
            <input v-model="post.title" required /> -->

            <label>Description:</label>
            <textarea v-model="post.description" required></textarea>
            <label>Stats:</label>
            <input type="number" v-model="post.stats" step="0.01" required />
            <!-- <label>Date Posted:</label>
            <input type="date" v-model="post.date_posted" required /> -->

            <button type="submit">Create Post</button>
        </form>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            post: {
                description: '',
                stats: null,
                // date_posted: '',
            },
        };
    },
    methods: {
        createPost() {
            // axios.post('http://127.0.0.1:8000/api/posts', this.post, {
            axios.post('/api/posts', this.post, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                },
            })
                .then(response => {
                    console.log('Post created successfully:', response.data);
                    // Optionally, you can redirect to the post detail page or update the post list
                })
                .catch(error => {
                    console.error('Error creating post:', error);
                });
        },
    },
};
</script>
  
<style scoped></style>
  
 