

import { createApp } from 'vue';
import axios from 'axios'
import App from './App.vue';
import router from './router';
const cors = require('cors');
import 'bootstrap/dist/css/bootstrap.css';




axios.defaults.baseURL = 'https://api-superorg.stagingurls.com/';

// axios.defaults.baseURL = 'http://127.0.0.1:8000/';




const app = createApp(App);
app.config.globalProperties.$http = axios;


createApp(App).use(router).use(cors).use(axios).mount('#app');




